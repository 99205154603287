.scroll::-webkit-scrollbar {
    width: 16px;
}

.scroll::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
}

.scroll::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #082946;
    background-clip: content-box;
}

.td-warning {
    background-color: #FBD469 !important;
}

.td-error {
    background-color: #f44336 !important;
}

.td-success {
    background-color: #95D890 !important;
}